import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AsyncButton } from '../../shared/components/asyncButton'
import { fetchPaymentDetails } from '../../redux/actions/users'
import { useLocation } from 'react-router-dom'
import { config } from '../../config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import AddInvoiceDetailsDialog from './AddInvoiceDetailsDialog'
import { BackButton } from '../../shared/components/backButton'
import { PulsingHeader } from '../../shared/components/pulsingHeader'

const useStyles = makeStyles((theme) => ({
    main: {
        overflow: 'hidden',
        flexWrap: 'nowrap',
        height: '100%',
        background: 'black',
        zoom: '0.75',
    },
    header: {
        fontSize: '1rem',
        alignItems: 'center',
    },
    textWrapper: {
        padding: theme.spacing(5),
    },
    buttonWrapper: {
        zIndex: 10,
        width: '100%',
        position: 'absolute',
        bottom: '0px',
        paddingTop: theme.spacing(2),
        paddingBottom: '55px',
        paddingRight: '55px',
        paddingLeft: '55px',
    },
    titleContainer: {
        maxHeight: '32px',
        marginBottom: '34px',
        textAlign: 'center',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    invisibleIframe: {
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        width: '0',
        height: '0',
        border: 'none',
        opacity: '0',
        transition: 'opacity .5s ease-in-out',
        zIndex: 99999,
    },
    textDesc: {
        margin: '0px 0px 0px 0px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    buttonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    button: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        borderRadius: '14px',
        maxWidth: '280px',
        width: '100%',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
}))

export default function RegisterCard() {
    const classes = useStyles()
    const { t } = useTranslation()
    const { search } = useLocation()
    const dispatch = useDispatch()
    const baseUrl = '/register-card'

    const paymentState = useSelector((state: any) => state.payment.paymentDetails)
    const user = useSelector((state: any) => state.user.login.value.user)
    const currency = useSelector((state: any) => state.paymentSettings.value.currency)
    const amount = useSelector((state: any) => state.paymentSettings.value.registrationAmount)

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const params = new URLSearchParams(search)
    const locationId = params.get('location')
    const chargerExternalId = params.get('charger')
    const connectorExternalId = params.get('connector')
    const pricelistId = params.get('pricelist')

    const previewRouteMatch = useRouteMatch(`${baseUrl}/:source`)
    const routeParams: any = previewRouteMatch?.params
    const source: string = routeParams?.source

    const history = useHistory()

    const trustPayStaticDetails: any = {
        baseUrl: 'https://amapi.trustpay.eu/mapi5/Card/PayPopup',
        amount: amount,
        currency: currency,
        url: `${
            config.version === 'dev' ? 'http://localhost:3000' : config.client
        }/waiting-for-payment.html?l=${locationId}&ch=${chargerExternalId}&c=${connectorExternalId}&p=${pricelistId}`,
        paymentType: 3,
        notificationUrl: `${config.proxy ? config.proxy + config.api : config.api}Pay/notification`,
    }

    const handleBackButtonEvent = () => {
        console.log(previewRouteMatch)
        if (source === 'settings') {
            history.push(`/settings`)
            return;
        } else if (source === 'map') {
            history.push(`/main-map`)
            return;
        } else {
            history.push(
                `/charging/${locationId}?charger=${chargerExternalId}&connector=${connectorExternalId}&pricelist=${pricelistId}`
            )
        }
    }

    const onDialogClose = () => {
        setDialogOpen(false)
        dispatch(
            fetchPaymentDetails(
                user.id,
                trustPayStaticDetails.amount,
                trustPayStaticDetails.currency,
                trustPayStaticDetails.paymentType
            )
        )
    }

    const openPopup = () => {
        const iframe = document.getElementById('TrustPayFrame')
        if (iframe) {
            iframe.style.opacity = '1'
            iframe.style.width = '100%'
            iframe.style.height = '100%'
            iframe.focus()
            ;(iframe as any).contentWindow.postMessage('redirectMessage', '*')
            ;(iframe as any).contentWindow.postMessage('authorizationMessage', '*')
        }
    }

    useEffect(() => {
        if (paymentState.value && paymentState.executed) {
            const script = document.createElement('script')
            script.setAttribute('id', 'trustpay-script')
            script.src = 'https://amapi.trustpay.eu/mapi5/Scripts/TrustPay/popup.js'
            script.onload = function () {
                setTimeout(() => {
                    openPopup()
                }, 0)
            }
            document.body.appendChild(script)

            return () => {
                if (script) document.body.removeChild(script)
            }
        }
    }, [paymentState])

    return (
        <>
            <Grid className={classes.main} container direction="column">
                <div style={{ zIndex: 5 }}>
                    <Grid container className={classes.header} direction="row">
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                style={{ marginBottom: '37px', marginTop: '23px', marginLeft: '23px' }}
                            >
                                <Grid item style={{ justifySelf: 'start' }}>
                                    <BackButton
                                        buttonProps={{
                                            onClick: (event: any) => {
                                                handleBackButtonEvent()
                                            },
                                        }}
                                    ></BackButton>
                                </Grid>
                                <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                    <PulsingHeader mainWindowScroll={window.pageYOffset} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography variant="h3" className={classes.title}>
                            {source === 'settings' || source === 'map' ? t('card.updateCard') : t('card.registerCard')}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.textWrapper}>
                        <Typography className={classes.textDesc}>
                            {source === 'settings' || source === 'map'
                                ? t('card.updateCardInfo')
                                : t('card.haveToRegisterCard')}
                            {t('card.confirmationProcedure')}
                        </Typography>
                    </Grid>
                </div>
                <div className={classes.bottomDecoration}></div>
                {paymentState.executed && paymentState.value && (
                    <iframe
                        title="TrustPay"
                        id="TrustPayFrame"
                        className={classes.invisibleIframe}
                        sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"
                        src={`${trustPayStaticDetails.baseUrl}?AccountId=${paymentState.value.accountId}&Amount=${
                            trustPayStaticDetails.amount
                        }&BillingStreet=${encodeURIComponent(user.street)}&BillingCity=${user.city}&BillingCountry=${
                            user.country
                        }&BillingPostcode=${user.zip}&CardHolder=${encodeURIComponent(
                            user.firstName + ' ' + user.lastName
                        )}&Currency=${encodeURIComponent(trustPayStaticDetails.currency)}&Email=${encodeURIComponent(
                            user.email
                        )}&Reference=${encodeURIComponent(paymentState.value.reference)}&Url=${encodeURIComponent(
                            trustPayStaticDetails.url
                        )}&PaymentType=${encodeURIComponent(trustPayStaticDetails.paymentType)}&Signature=${
                            paymentState.value.signature
                        }&NotificationUrl=${encodeURIComponent(
                            `${trustPayStaticDetails.notificationUrl}?Reference=${encodeURIComponent(
                                paymentState.value.reference
                            )}`
                        )}`}
                    ></iframe>
                )}
                <Grid item className={classes.buttonWrapper}>
                    <AsyncButton
                        buttonProps={{
                            className: classes.button,
                            variant: 'contained',
                            color: 'primary',
                            type: 'submit',
                            onClick: () => {
                                setDialogOpen(true)
                            },
                        }}
                        inProgress={!paymentState.executed}
                    >
                        <p className={classes.buttonText}>
                            {source === 'settings' ? t('card.updateCard') : t('card.registerCard')}
                        </p>
                    </AsyncButton>
                </Grid>
            </Grid>
            <AddInvoiceDetailsDialog open={dialogOpen} onClose={onDialogClose} />
        </>
    )
}
