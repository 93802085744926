import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    Typography,
    Grid,
    Button,
    Snackbar,
    Dialog,
    DialogTitle,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormControl,
    FormControlLabel,
    Divider,
    InputLabel,
    FormHelperText,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { Field, Form, useForm } from 'react-final-form'
import { editUser, clearEditState, cardRegister, fetchUser } from '../../redux/actions/users'
import { RegisteredUsersOnly } from '../../core/anonymusUser/registeredUsersOnly'
import { config } from '../../config'
import { ValidationErrors } from 'final-form'
import { setRequiredErrors } from '../../utils/ui'
import { CountrySelect } from '../../shared/components/countrySelectStyled'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import { CustomTextField as CustomTextFieldRff } from '../../shared/components/customInputRff'
import { CustomTextField } from '../../shared/components/customInput'
import IdCard from '../../static/icons/icon-id-card-blue.svg'
import PhoneIcon from '@material-ui/icons/Phone'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import PlaceIcon from '@material-ui/icons/Place'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import PublicIcon from '@material-ui/icons/Public'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import ClearIcon from '@material-ui/icons/Clear'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { removeToken } from '../../config'
import { useHistory } from 'react-router-dom'
import { logout } from '../../shared/redux/actions/common'
import { LanguageSettings } from '../../utils/languageSettings'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { useLocation } from 'react-router-dom'
import { UserRfid } from '../../shared/types/user'
import { v4 as uuidv4 } from 'uuid'
import { ChargerConnectorSocketType, getChargerConnectorSocketTypes } from '../../shared/types/charger'
import RadioButtonUnchecked from '../../static/icons/radio_button_unchecked_blue.svg'
import RadioButtonChecked from '../../static/icons/radio_button_checked_blue.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    carSeparator: {
        margin: '15px 0',
        backgroundColor: '#565b5c', // Matches the form border color
        height: '1px',
        width: '100%',
        opacity: 0.6, // Slightly faded effect
    },
    radioUnchecked: {
        width: '14px',
        height: '14px',
        display: 'inline-block',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
    },

    radioChecked: {
        width: '14px',
        height: '14px',
        display: 'inline-block',
        backgroundImage: `url(${RadioButtonChecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
    },
    defaultLabel: {
        color: 'white', // Text color
        fontSize: '18px', // Adjust size
        fontWeight: 500, // Optional: make it a bit bolder
    },
    selectContainer: {
        borderRadius: '9px',
        background: 'black',
        border: 'solid 1px #565b5c',
        paddingRight: '26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '6px',
        height: '45px',
    },

    select: {
        textAlign: 'left',
        width: '100%',
        fontFamily: "'Mulish', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    selectRoot: {
        paddingRight: '0px !important',
        fontSize: '11px',
        paddingLeft: '21px',
        marginLeft: '10px',
        backgroundPosition: 'left',
        backgroundSize: '16px 16px',
        backgroundRepeat: 'no-repeat',
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },
        color: 'white !important',
        backgroundColor: 'black !important',

        border: 'none',
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 1px 5px 0px rgba(0,0,0,0.06)',
        '&$selected': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: 'black', // updated backgroundColor
        },
    },
    menuItem: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'white !important',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },
        fontSize: '12px',
        background: '#363b3d',
        color: 'white',
        backgroundPosition: 'left, right 11px top 8px',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
        marginLeft: '10px',
        paddingLeft: '25px',
        paddingRight: '0px',
        marginRight: '10px',
        minHeight: '30px',
    },
    menuProps: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#363b3d !important',
            color: 'white',
            fontSize: '12px',
            backgroundPosition: 'left, right 11px top 8px',
            backgroundImage: `url(${RadioButtonChecked})`,
            backgroundSize: '14px 14px',
            backgroundRepeat: 'no-repeat',
            marginLeft: '10px',
            paddingLeft: '25px',
            minHeight: '30px',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#363b3d',
            borderRadius: '10px',
        },
        '& .MuiList-padding': {
            backgroundColor: '#363b3d',
            borderRadius: '10px',
        },
        '& .MuiPopover-paper': {
            left: '0 !important',
            right: '0 !important',
            margin: '0 23px 0 23px !important',
        },
    },
    main: {
        background: 'black',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        fontSize: '1rem',
        alignItems: 'center',
    },
    formWrapper: {
        zIndex: 4,
        overflowY: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
    },
    formItem: {
        zoom: '0.75',
        paddingLeft: '26px',
        paddingRight: '26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '26px',
    },
    formItemCountry: {
        paddingLeft: '19.5px',
        paddingRight: '19.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '19.5px',
    },
    termsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        paddingBottom: '15px',
    },
    terms: {
        height: '30px',
        width: '75%',
        marginTop: '15px',
        marginBottom: '5px',
        fontFamily: 'Muli',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    buttonWrapper: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    autoScroll: {
        overflowY: 'auto',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    titleContainer: {
        maxHeight: '24px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    buttonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    buttonContainer: {
        marginTop: '26px',
        width: '100%',
    },
    buttonBlue: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '45px',
        width: '100%',
        maxWidth: '280px',
        borderRadius: '11px',
    },
    rfidButton: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '45px',
        width: '50%',
        maxWidth: '280px',
        borderRadius: '11px',
        marginLeft: '20px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    accordionRoot: {
        background: 'unset',
        paddingLeft: '6px',
        paddingRight: '6px',
    },
    accordionHeader: {
        color: 'white',
    },
    disabledButtonText: {
        color: '#757575',
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        fontFamily: "'Mulish', sans-serif",
    },
    rfidList: {
        zoom: '0.75',
        paddingLeft: '26px',
        paddingRight: '26px',
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    rfidRecord: {
        border: 'solid 1px #565b5c',
        background: 'black',
        borderRadius: '8px',
        color: 'white',
        marginTop: '4px',
        marginRight: '4px',
    },
    rfidDeleteButton: {
        color: 'white',
        '&:hover': {
            color: '#37c0ca',
        },
    },
    logoutButton: {
        width: '100%',
        maxWidth: '280px',
        height: '40px',
        borderRadius: '14px',
        background: 'transparent',
    },
    logoutText: {
        color: 'white',
        fontSize: '11px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        fontFamily: "'Mulish', sans-serif",
        borderBottom: '#37c0ca solid 1px',
        borderBottomStyle: 'groove',
        padding: '2px',
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '8px',
        height: '17px',
    },

    selectIconLeftContainer: {
        zIndex: 5,
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectIconLeftContainerCountry: {
        zIndex: 5,
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputIconLeftCountry: {
        color: '#37c0ca',
        marginBottom: '15px',
        marginTop: '16px',
        marginLeft: '8px',
        height: '13px',
    },
    selectIconLeft: {
        width: '18px',
        color: '#37c0ca',
        marginLeft: '8px',
        marginRight: '8px',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    headerConstainer: {
        marginBottom: '10px',
        marginTop: '23px',
        marginLeft: '23px',
        marginRight: '23px',
    },
    cardUpdateButton: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #565b5c',
        background: 'black',
        width: '100%',
    },
    cardUpdateButtonContainer: {
        zoom: '0.75',
        marginTop: '26px',
        width: '100%',
        paddingRight: '26px',
        paddingLeft: '26px',
    },
    popupNotification: {
        marginBottom: '5em',
    },
    dialog: {
        color: 'white',
        borderRadius: '10px',

        backgroundColor: 'transparent',
    },
    textDesc: {
        margin: '0px 0px 0px 0px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    '@global': {
        '.MuiDialog-paperScrollPaper': {
            backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 1) 100%)',
            borderRadius: '16px',
        },
    },
}))

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined

type Message = {
    open: boolean
    severity: Severity
    message: string
}

export default function Settings() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: any) => state.user.login.value.user)
    const tenant = useSelector((state: any) => state.tenant.value)
    const editState = useSelector((state: any) => state.user.edit)
    const isAnonymousUser = useSelector((state: any) => state.user.login.value.isAnonymous)

    const paymentRequestId = useSelector((state: any) => state.user.login.value.user.paymentRequestId)
    const cardExpiration = useSelector((state: any) => state.user.login.value.user.cardExpiration)
    const [rfids, setRfids] = useState<UserRfid[]>(user?.rfids || [])
    const [cars, setCars] = useState<any[]>(user?.cars || [])
    const [inputValue, setInputValue] = useState<string>('')
    // const [inputErrorState, setInputErrorState] = useState<boolean>(false)
    const [fieldErrors, setFieldErrors] = useState<Record<string, Record<string, string>>>({})

    const userId = useSelector((state: any) => state.user.login.value.user.id)

    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const handleRedirectToCardRegister = () => {
        history.push('/register-card/settings')
    }

    const { search } = useLocation()

    const [message, setMessage] = useState<Message>({ open: false, severity: undefined, message: '' })

    const handleLogout = () => {
        removeToken()
        dispatch(logout())
    }

    const handleAddRfid = () => {
        if (inputValue.trim()) {
            const newRfid: UserRfid = { rfid: inputValue, userId: user?.id }
            const rfidExists = rfids.some((rfidItem) => rfidItem.rfid === newRfid.rfid)

            if (!rfidExists) {
                setRfids((prevRfids) => [...prevRfids, newRfid])
            }
            setInputValue('')
        }
    }

    const handleRemoveRfid = (rfidToRemove: string) => {
        setRfids((prevRfids) => prevRfids.filter((rfid) => rfid.rfid !== rfidToRemove))
    }

    const handleDialogState = () => {
        setOpenDialog(!openDialog)
    }

    const cardValidate = () => {
        var validationResult
        if (user.cardExpiration) {
            var currentDate = new Date()
            var expirationDate = new Date(
                String(currentDate.getFullYear()).substring(0, 2) +
                user.cardExpiration.substring(2, 4) +
                '-' +
                user.cardExpiration.substring(0, 2)
            )
            if (
                currentDate.getMonth() === expirationDate.getMonth() &&
                currentDate.getFullYear() === expirationDate.getFullYear()
            ) {
                validationResult = <div style={{ color: '#ffa3a3' }}>{t('settings.cardWillExpire')}</div>
            } else if (
                currentDate.getMonth() > expirationDate.getMonth() &&
                currentDate.getFullYear() >= expirationDate.getFullYear()
            ) {
                validationResult = <div style={{ color: '#ff8888' }}>{t('settings.cardAfterExpiration')}</div>
            } else {
                validationResult = <div style={{ color: '#37c0ca' }}>{t('settings.cardValid')}</div>
            }
        } else {
            if (user.paymentRequestId) {
                validationResult = <div style={{ color: '#ff8888' }}>{t('settings.cardWaiting')}</div>
            } else {
                validationResult = <div style={{ color: '#ff8888' }}>{t('settings.cardNotAssigned')}</div>
            }
        }
        return validationResult
    }

    const handleAddCar = () => {
        const errors = validateCars(cars, t)

        if (errors) {
            console.log('Validation failed:', errors) // Debugging output
            return // 🚫 Stop if there are validation errors
        }

        const newCar = {
            id: uuidv4(),
            brand: '',
            model: '',
            carLicensePlate: '',
            year: '',
            socketType: [0],
            default: true,
        }

        const updatedCars = cars.map((car) => ({ ...car, default: false }))
        updatedCars.push(newCar)

        setCars((prevCars) => {
            const updatedCars = [...prevCars, newCar]
            return updatedCars
        })
    }

    // Remove a car, ensuring there is always at least one car
    const handleRemoveCar = (id: string) => {
        setCars((prevCars) => {
            // Find the car being removed
            const carToRemove = prevCars.find((car) => car.id === id)

            // Remove the car from the list
            let updatedCars = prevCars.filter((car) => car.id !== id)

            // If the removed car was default, assign default to the last remaining car
            if (carToRemove?.default && updatedCars.length > 0) {
                updatedCars = updatedCars.map((car, i) => ({
                    ...car,
                    default: i === updatedCars.length - 1, // Set default to the last car
                }))
            }

            return updatedCars
        })
    }

    const handleCarFieldChange = (id: string, field: string, value: any) => {
        setCars((prevCars) => prevCars.map((car) => (car.id === id ? { ...car, [field]: value } : car)))
        setFieldErrors((prevErrors) => {
            const newErrors = { ...prevErrors }
            if (newErrors[id]) {
                delete newErrors[id][field]
                if (Object.keys(newErrors[id]).length === 0) {
                    delete newErrors[id]
                }
            }
            return newErrors
        })
    }

    const validateCars = (cars: any[], t: (key: string) => string) => {
        const errors: Record<string, Record<string, string>> = {}

        cars.forEach((car, index) => {
            const carErrors: Record<string, string> = {}

            if (!car.brand?.trim()) carErrors.brand = t('register.required')
            if (!car.model?.trim()) carErrors.model = t('register.required')
            if (!car.carLicensePlate?.trim()) carErrors.carLicensePlate = t('register.required')
            if (!car.socketType || car.socketType.length === 0) carErrors.socketType = t('register.required')

            if (Object.keys(carErrors).length > 0) {
                errors[car.id] = carErrors
            }
        })

        setFieldErrors(errors)

        return Object.keys(errors).length > 0 ? errors : null
    }

    const socketTypeLabels = getChargerConnectorSocketTypes(t)

    const mapSocketTypes = (types: any[]) => {
        return types.map((type) => socketTypeLabels.find((socket) => socket.id === type)?.label || type).join(', ')
    }

    useEffect(() => {
        for (let i = 0; i < 3; i++) {
            setTimeout(() => {
                if (paymentRequestId && !cardExpiration) {
                    dispatch(fetchUser())
                }
            }, 3000 * (i + 1))
        }
    }, [dispatch, paymentRequestId, cardExpiration])

    useEffect(() => {
        const params = new URLSearchParams(search)
        const result = params.get('ResultCode')
        const successfulTransaction = '0'
        if (result === successfulTransaction) {
            dispatch(cardRegister(userId, params))
        }
        if (result && result !== successfulTransaction) {
            console.log('AcquirerResponseId', params.get('AcquirerResponseId'))
        }
    }, [dispatch, search, userId])

    useEffect(() => {
        if (editState.executed) {
            if (editState.error && editState.error.errors.length == 0) {
                setMessage({ open: true, severity: 'error', message: t('common.unknownValidationFailure') })
            } else if (!editState.error && editState.value) {
                setMessage({ open: true, severity: 'success', message: t('settings.changesSaved') })
            }
        }
    }, [editState, t])

    useEffect(() => {
        return () => {
            dispatch(clearEditState())
        }
    }, [dispatch])

    useEffect(() => {
        if (user?.cars) {
            setCars(user.cars)
        }
    }, [user])

    return (
        <>
            <div style={{ background: 'black', height: '100%' }}>
                {!isAnonymousUser ? (
                    <Grid className={classes.main} container direction="column">
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                className={classes.headerConstainer}
                            >
                                <Grid item style={{ justifySelf: 'start' }}></Grid>
                                <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                    <PulsingHeader mainWindowScroll={window.pageYOffset} />
                                    <LanguageSettings />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.title}>
                                {t('menu.settings')}
                            </Typography>
                        </Grid>
                        <Grid container direction="column" wrap="nowrap" className={classes.formWrapper}>
                            <Form
                                key={user.id}
                                onSubmit={(model: any) => {
                                    const errors = validateCars(cars, t)

                                    if (errors) {
                                        console.log('Validation failed:', errors) // Debugging output
                                        return
                                    }

                                    const newModel = {
                                        ...model,
                                        rfids,
                                        cars,
                                    }
                                    dispatch(editUser(newModel))
                                }}
                                initialValues={user}
                                validate={(model: any): ValidationErrors => {
                                    const result: any = {}
                                    let requiredFields = ['firstName', 'lastName']
                                    if (user.paymentRequestId) {
                                        requiredFields = [...requiredFields, 'street', 'zip', 'city', 'country']
                                    }
                                    setRequiredErrors(result, model, requiredFields, t('register.required'))

                                    return result
                                }}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        <Grid container className={classes.autoScroll}>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextFieldRff
                                                    label={t('settings.firstname')}
                                                    itemName={'firstName'}
                                                    password={false}
                                                    children={
                                                        <img
                                                            alt="Card"
                                                            src={IdCard}
                                                            className={classes.inputIconLeft}
                                                        />
                                                    }
                                                ></CustomTextFieldRff>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextFieldRff
                                                    label={t('settings.lastname')}
                                                    itemName={'lastName'}
                                                    password={false}
                                                    children={
                                                        <img
                                                            alt="Card"
                                                            src={IdCard}
                                                            className={classes.inputIconLeft}
                                                        />
                                                    }
                                                ></CustomTextFieldRff>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextFieldRff
                                                    label={t('settings.phoneNumber')}
                                                    itemName={'phone'}
                                                    password={false}
                                                    required={false}
                                                    children={<PhoneIcon className={classes.inputIconLeft} />}
                                                ></CustomTextFieldRff>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    errorState={false}
                                                    itemName={'rfid'}
                                                    textFieldProps={{
                                                        onChange: (event: any) =>
                                                            setInputValue(event.target.value.trim()),
                                                        label: t('settings.rfid'),
                                                        value: inputValue,
                                                    }}
                                                    password={false}
                                                    standardChildren={
                                                        <CollectionsBookmarkIcon className={classes.inputIconLeft} />
                                                    }
                                                ></CustomTextField>
                                                <Button
                                                    className={classes.rfidButton}
                                                    onClick={handleAddRfid}
                                                    disabled={!inputValue.trim()}
                                                >
                                                    <p
                                                        className={
                                                            inputValue.trim()
                                                                ? classes.buttonText
                                                                : classes.disabledButtonText
                                                        }
                                                    >
                                                        {' '}
                                                        {t('settings.addToList')}
                                                    </p>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} className={classes.rfidList}>
                                                {rfids.map((rfid) => (
                                                    <Chip
                                                        className={classes.rfidRecord}
                                                        key={rfid.id}
                                                        label={rfid.rfid}
                                                        onDelete={() => handleRemoveRfid(rfid.rfid)}
                                                        deleteIcon={<ClearIcon className={classes.rfidDeleteButton} />}
                                                    />
                                                ))}
                                            </Grid>
                                            <Grid item className={classes.cardUpdateButtonContainer}>
                                                <Button
                                                    className={classes.cardUpdateButton}
                                                    onClick={handleDialogState}
                                                >
                                                    <CreditCardIcon
                                                        className={classes.inputIconLeft}
                                                        style={{ marginTop: '14px' }}
                                                    />
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div style={{ width: '100%', height: '10px' }}></div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{ width: '100%' }}>
                                                                <Typography
                                                                    className={classes.buttonText}
                                                                    style={{ textAlign: 'left', marginLeft: '15px' }}
                                                                >
                                                                    {t('card.updateCard')}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{ width: '100%', height: '10px' }}>
                                                                <Typography
                                                                    className={classes.buttonText}
                                                                    style={{
                                                                        fontSize: '9px',
                                                                        textAlign: 'left',
                                                                        marginLeft: '15px',
                                                                    }}
                                                                >
                                                                    {cardValidate()}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <ArrowForwardIosIcon
                                                        style={{
                                                            color: '#37c0ca',
                                                            width: '25px',
                                                            height: '25px',
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                <Accordion className={classes.accordionRoot}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <KeyboardArrowDownIcon
                                                                style={{
                                                                    color: '#37c0ca',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }}
                                                            />
                                                        }
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.accordionHeader}>
                                                            {t('settings.address')}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.street')}
                                                                    itemName={'street'}
                                                                    password={false}
                                                                    required={user.paymentRequestId !== null}
                                                                    children={
                                                                        <PlaceIcon className={classes.inputIconLeft} />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.zip')}
                                                                    itemName={'zip'}
                                                                    password={false}
                                                                    required={user.paymentRequestId !== null}
                                                                    children={
                                                                        <LocationCityIcon
                                                                            className={classes.inputIconLeft}
                                                                        />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.city')}
                                                                    itemName={'city'}
                                                                    password={false}
                                                                    required={user.paymentRequestId !== null}
                                                                    children={
                                                                        <LocationCityIcon
                                                                            className={classes.inputIconLeft}
                                                                        />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.formItemCountry}>
                                                                <CountrySelect
                                                                    child={
                                                                        <PublicIcon
                                                                            className={classes.inputIconLeftCountry}
                                                                        />
                                                                    }
                                                                    name="country"
                                                                    required={user.paymentRequestId !== null}
                                                                    label={t('settings.state')}
                                                                    variant="standard"
                                                                ></CountrySelect>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                <Accordion className={classes.accordionRoot}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <KeyboardArrowDownIcon
                                                                style={{
                                                                    color: '#37c0ca',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }}
                                                            />
                                                        }
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.accordionHeader}>
                                                            {t('settings.companyInformation')}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.companyName')}
                                                                    itemName={'companyName'}
                                                                    password={false}
                                                                    required={false}
                                                                    children={
                                                                        <DashboardIcon
                                                                            className={classes.inputIconLeft}
                                                                        />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.companyId')}
                                                                    itemName={'companyId'}
                                                                    password={false}
                                                                    required={false}
                                                                    children={
                                                                        <DashboardIcon
                                                                            className={classes.inputIconLeft}
                                                                        />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.taxId')}
                                                                    itemName={'taxId'}
                                                                    password={false}
                                                                    required={false}
                                                                    children={
                                                                        <CollectionsBookmarkIcon
                                                                            className={classes.inputIconLeft}
                                                                        />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <CustomTextFieldRff
                                                                    label={t('settings.vatId')}
                                                                    itemName={'vatId'}
                                                                    password={false}
                                                                    required={false}
                                                                    children={
                                                                        <CollectionsBookmarkIcon
                                                                            className={classes.inputIconLeft}
                                                                        />
                                                                    }
                                                                ></CustomTextFieldRff>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                <Accordion className={classes.accordionRoot}>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <KeyboardArrowDownIcon
                                                                style={{
                                                                    color: '#37c0ca',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }}
                                                            />
                                                        }
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.accordionHeader}>
                                                            {t('settings.manageCars')}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            {cars.map((car) => (
                                                                <React.Fragment>
                                                                    <Grid item xs={12} className={classes.formItem}>
                                                                        <CustomTextField
                                                                            errorState={!!fieldErrors[car.id]?.brand}
                                                                            helperText={!car.brand && !!fieldErrors[car.id]?.brand ? t('register.required') : undefined}
                                                                            itemName={`brand-${car.id}`}
                                                                            password={false}
                                                                            textFieldProps={{
                                                                                value: car.brand,
                                                                                onChange: (event: any) =>
                                                                                    handleCarFieldChange(
                                                                                        car.id,
                                                                                        'brand',
                                                                                        event.target.value
                                                                                    ),
                                                                                label: t('settings.brand'),
                                                                            }}
                                                                            standardChildren={
                                                                                <CollectionsBookmarkIcon
                                                                                    className={classes.inputIconLeft}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} className={classes.formItem}>
                                                                        <CustomTextField
                                                                            errorState={!!fieldErrors[car.id]?.model}
                                                                            helperText={!car.model && !!fieldErrors[car.id]?.model ? t('register.required') : undefined}
                                                                            itemName={`model-${car.id}`}
                                                                            password={false}
                                                                            textFieldProps={{
                                                                                value: car.model,
                                                                                onChange: (event: any) =>
                                                                                    handleCarFieldChange(
                                                                                        car.id,
                                                                                        'model',
                                                                                        event.target.value
                                                                                    ),
                                                                                label: t('settings.model'),
                                                                            }}
                                                                            standardChildren={
                                                                                <CollectionsBookmarkIcon
                                                                                    className={classes.inputIconLeft}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} className={classes.formItem}>
                                                                        <CustomTextField
                                                                            errorState={!!fieldErrors[car.id]?.carLicensePlate}
                                                                            helperText={!car.carLicensePlate && !!fieldErrors[car.id]?.carLicensePlate ? t('register.required') : undefined}
                                                                            itemName={`carLicensePlate-${car.id}`}
                                                                            password={false}
                                                                            textFieldProps={{
                                                                                value: car.carLicensePlate,
                                                                                onChange: (event: any) =>
                                                                                    handleCarFieldChange(
                                                                                        car.id,
                                                                                        'carLicensePlate',
                                                                                        event.target.value
                                                                                    ),
                                                                                label: t('settings.carLicensePlate'),
                                                                            }}
                                                                            standardChildren={
                                                                                <CollectionsBookmarkIcon
                                                                                    className={classes.inputIconLeft}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} className={classes.formItem}>
                                                                        <CustomTextField
                                                                            errorState={false}
                                                                            itemName={`year-${car.id}`}
                                                                            password={false}
                                                                            textFieldProps={{
                                                                                value: car.year,
                                                                                onChange: (event: any) =>
                                                                                    handleCarFieldChange(
                                                                                        car.id,
                                                                                        'year',
                                                                                        event.target.value
                                                                                    ),
                                                                                label: t('settings.year'),
                                                                            }}
                                                                            standardChildren={
                                                                                <CollectionsBookmarkIcon
                                                                                    className={classes.inputIconLeft}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid xs={12} className={classes.selectContainer} style={fieldErrors[car.id]?.socketType ? { borderColor: '#de384a' } : {}}>
                                                                        <Grid item style={{ width: '5px' }}></Grid>
                                                                        <Grid
                                                                            item
                                                                            style={{ width: 'calc(100% - 20px)' }}
                                                                        >
                                                                            <FormControl className={classes.select}>
                                                                                <Select
                                                                                    IconComponent={() => (
                                                                                        <ExpandMoreIcon
                                                                                            style={{ color: 'white' }}
                                                                                        />
                                                                                    )}
                                                                                    disableUnderline={true}
                                                                                    classes={{
                                                                                        root: classes.selectRoot,
                                                                                        select: classes.selectRoot,
                                                                                        selectMenu: classes.selectRoot,
                                                                                    }}
                                                                                    multiple
                                                                                    fullWidth
                                                                                    MenuProps={{
                                                                                        className: classes.menuProps,
                                                                                        PaperProps: {
                                                                                            style: {
                                                                                                width: 'calc(100% - 62px)', // Matches select width
                                                                                                minWidth: '200px', // Ensures a minimum width
                                                                                            },
                                                                                        },
                                                                                        anchorOrigin: {
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'left',
                                                                                        },
                                                                                        transformOrigin: {
                                                                                            vertical: 'top',
                                                                                            horizontal: 'left',
                                                                                        },
                                                                                        getContentAnchorEl: null,
                                                                                    }}
                                                                                    value={car.socketType || []}
                                                                                    onChange={(e) =>
                                                                                        handleCarFieldChange(
                                                                                            car.id,
                                                                                            'socketType',
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                    variant="filled"
                                                                                    label={t('settings.socketType')}
                                                                                    renderValue={(selected) =>
                                                                                        mapSocketTypes(
                                                                                            selected as ChargerConnectorSocketType[]
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {socketTypeLabels.map(
                                                                                        (socketType, i) => (
                                                                                            <MenuItem
                                                                                                key={socketType.id}
                                                                                                value={socketType.id}
                                                                                                className={
                                                                                                    classes.menuItem
                                                                                                }
                                                                                                style={{
                                                                                                    borderTop:
                                                                                                        i !== 0
                                                                                                            ? '1px solid #484e51'
                                                                                                            : 'none',
                                                                                                }}
                                                                                            >
                                                                                                {socketType.label}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    )}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {cars.length > 1 && (
                                                                        <Grid item xs={12}>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={car.default}
                                                                                        onChange={() => {
                                                                                            const updatedCars = cars.map((c) => ({
                                                                                                ...c,
                                                                                                default: c.id === car.id, // Only one car can be default
                                                                                            }));
                                                                                            setCars(updatedCars);
                                                                                        }}
                                                                                        icon={<span className={classes.radioUnchecked} />}
                                                                                        checkedIcon={<span className={classes.radioChecked} />}
                                                                                    />
                                                                                }
                                                                                label={t('settings.defaultCar')}
                                                                                classes={{ label: classes.defaultLabel }}
                                                                            />
                                                                        </Grid>

                                                                    )}
                                                                    {cars.length > 1 && (
                                                                        <Grid item xs={12}>
                                                                            <Grid item xs={12} className={classes.formItem}>
                                                                                <Button
                                                                                    className={classes.logoutButton}
                                                                                    onClick={() => handleRemoveCar(car.id)}
                                                                                    disabled={cars.length === 1} // Disable if only one car is left
                                                                                >
                                                                                    <p className={classes.logoutText}>{t('settings.removeCar')}</p>
                                                                                </Button>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Divider className={classes.carSeparator} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                            <Grid item xs={12} className={classes.formItem}>
                                                                <Button className={classes.buttonBlue} onClick={handleAddCar}>
                                                                    <p className={classes.buttonText}>{t('settings.addCar')}</p>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>

                                            <Grid item className={classes.buttonContainer}>
                                                <Button className={classes.buttonBlue} type="submit">
                                                    <p className={classes.buttonText}> {t('common.save')}</p>
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '15px', width: '100%' }}>
                                                <Button className={classes.logoutButton} onClick={handleLogout}>
                                                    <p className={classes.logoutText}> {t('common.logout')}</p>
                                                </Button>
                                            </Grid>
                                            <Snackbar
                                                open={message.open}
                                                className={classes.popupNotification}
                                                autoHideDuration={5000}
                                                onClose={() =>
                                                    setMessage({ open: false, severity: undefined, message: '' })
                                                }
                                            >
                                                <Alert
                                                    onClose={() =>
                                                        setMessage({ open: false, severity: undefined, message: '' })
                                                    }
                                                    severity={message.severity}
                                                >
                                                    {message.message}
                                                </Alert>
                                            </Snackbar>
                                            <Grid item className={classes.termsContainer}>
                                                <div className={classes.terms}>
                                                    {' '}
                                                    {t('settings.readOur')}
                                                    <a
                                                        href={`${config.proxy ?? ''}${config.api
                                                            }Settings/TermsAndConditions`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('common.terms2')}
                                                    </a>
                                                    {t('common.or')}
                                                    <a
                                                        href={`${config.proxy ?? ''}${config.api}Settings/Gdpr`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('common.gdpr2')}
                                                    </a>
                                                    .
                                                    {tenant.aboutPage ? (
                                                        <p>
                                                            <a
                                                                href={tenant.aboutPage}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {t('common.aboutUs')}
                                                            </a>
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </Grid>
                                            <div style={{ height: '150px' }} />
                                        </Grid>
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <RegisteredUsersOnly title={t('menu.settings')} languageSettings={true} />
                )}
                <BottomControlPanel positionAbsolute={true} actualPage="Settings" />
                <div className={classes.bottomDecoration}></div>
            </div>
            <Dialog open={openDialog} className={classes.dialog}>
                <DialogTitle style={{ color: 'white' }}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>{t('common.warning')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ marginTop: '10px', width: '100%' }} className={classes.textDesc}>
                            {t('settings.updateCardDialog')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '10px', width: '100%' }}>
                        <Button className={classes.logoutButton} onClick={handleDialogState} style={{ width: '50%' }}>
                            <p
                                className={classes.logoutText}
                                style={{
                                    color: '#ffa3a3',
                                    borderBottom: '#ffa3a3 solid 1px',
                                    borderBottomStyle: 'groove',
                                }}
                            >
                                {t('common.cancel')}
                            </p>
                        </Button>
                        <Button
                            className={classes.logoutButton}
                            onClick={handleRedirectToCardRegister}
                            style={{ width: '50%' }}
                        >
                            <p className={classes.logoutText} style={{ color: '#37c0ca' }}>
                                {t('common.confirm')}
                            </p>
                        </Button>
                    </Grid>
                </DialogTitle>
            </Dialog>
        </>
    )
}
