import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Snackbar, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import { sendJson, getJson } from '../../shared/utils/api'
import { setToken, getAppKey, config, getMainTenantId } from '../../config'
import { login, promiseError } from '../../shared/redux/actions/common'
import AuthorizationError from '../../shared/utils/authorizationError'
import { AsyncButton } from '../../shared/components/asyncButton'
import { BackButton } from '../../shared/components/backButton'
import { CustomTextField } from '../../shared/components/customInput'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import { usePromise } from '../../shared/utils/usePromise'
import usePromiseResult from '../../shared/utils/usePromiseResult'
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom'
//import GoogleLogin from 'react-google-login'
import { useRouteMatch } from 'react-router-dom'
//import { ProgressOverlay } from '../../shared/components/progressOverlay'
import { AppVersion } from './../../shared/components/AppVersion'
import loginStyles from './loginStyles'
import EnvelopeIcon from '../../static/icons/icon-envelope-blue.svg'
import PasswordIcon from '../../static/icons/icon-password-blue.svg'
import LogoGoogle from '../../static/icons/google-logo.png'
import EnvelopeRedIcon from '../../static/icons/icon-envelope-red.svg'
import PasswordRedIcon from '../../static/icons/icon-password-red.svg'

const loginAction = (username: string, password: string) =>
    sendJson('authorization', 'POST', null, {
        Authorization: 'Basic ' + btoa(unescape(encodeURIComponent(`${username}:${password}`))),
    })

const loginAnonymousAction = (appKey: string) =>
    sendJson('authorization/anonymous', 'POST', null, {
        ApplicationKey: appKey,
    })

//const googleAction = (response: any) => sendJson('Authorization/google', 'POST', response.getAuthResponse().id_token)

export interface IProps extends RouteComponentProps {
    returnUrl: string
}

const Login: React.FC<IProps> = ({ returnUrl, history, location }) => {
    const { t } = useTranslation()
    const classes = loginStyles()

    const dispatch = useDispatch()

    const appKey = getAppKey()
    const tenant = useSelector((state: any) => state.tenant.value)
    const mainTenantId = getMainTenantId()
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [inputErrorState, setInputErrorState] = useState<boolean>(false)
    const [showResentButton, setShowResentButton] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState({ message: undefined, autohide: false })

    //const [googleApiResponse, setGoogleApiResponse] = useState<any>()

    const [errorMessage, setErrorMessage] = useState<string>()

    const [loginAnonymousStart, loginAnonymousInProgress, loginAnonymousResult, loginAnonymousError] =
        usePromise(loginAnonymousAction)

    const [loginStart, loginInProgress, loginResult, loginError] = usePromise(loginAction)

    //const [googleStart, googleInProgress, googleResult, googleError] = usePromise(googleAction)

    const googleAuthorizationUrl = encodeURIComponent(
        `${config.version === 'dev' ? 'http://localhost:3000' : config.client}/waiting-for-google-authorization.html`
    )

    const [googleSettings] = usePromiseResult(
        'login',
        () => {
            return Promise.all([
                getJson('Settings/Google', {
                    ApplicationKey: appKey,
                }),
            ])
        },
        []
    )

    const sendConfirmationEmail = (username: string, callback: Function) =>
        sendJson('authorization/sendConfirmationEmail', 'POST', username).then((response) => {
            if (response) {
                callback(false)
                dispatch(promiseError(t('login.confirmationEmailResent')))
            }
        })

    /** read token test */
    const baseUrl = '/login'
    const routeMatch = useRouteMatch(`${baseUrl}/:id_token`)
    const routeParams: any = routeMatch?.params
    const idToken: string = routeParams?.id_token

    useEffect(() => {
        if (idToken !== null && idToken && idToken !== '') {
            if (idToken.includes('id_token=')) {
                var googleToken = idToken.replace('id_token=', '')
                var result = sendJson('Authorization/google', 'POST', googleToken).then((response) => {
                    if (response) {
                        if (response.token) {
                            setToken(response.token)
                            dispatch(login())
                            if (returnUrl) {
                                history.push(returnUrl)
                            }
                        } else {
                            setShowResentButton(true)
                            dispatch(promiseError(t('login.forgotPasswordNotConfirmedAccount')))
                        }
                    }
                })
                console.log('result -' + result)
            }
        }
    }, [dispatch, history, idToken, returnUrl, t])
    /** read token test */

    useEffect(() => {
        if (location.search?.includes('registrationSuccess') === true) {
            setSuccessMessage({ message: t('login.registrationSuccess'), autohide: false })
        } else if (location.search?.includes('confirmationSuccess') === true) {
            setSuccessMessage({ message: t('login.confirmationSuccess'), autohide: true })
        } else if (location.search?.includes('forgotPasswordRequested') === true) {
            setSuccessMessage({ message: t('login.forgotPasswordSent'), autohide: true })
        } else if (location.search?.includes('resetPasswordSuccess') === true) {
            setSuccessMessage({ message: t('login.resetPasswordSuccess'), autohide: true })
        }
    }, [location.search, t])

    useEffect(() => {
        if (loginAnonymousError) {
            dispatch(promiseError(t('login.error', { error: loginAnonymousError })))
        }
        if (loginAnonymousResult) {
            if (loginAnonymousResult.token) {
                setToken(loginAnonymousResult.token)
                dispatch(login(true))
                if (returnUrl) {
                    history.push(returnUrl)
                }
            }
        }
    }, [dispatch, t, loginAnonymousError, loginAnonymousResult, returnUrl, history])

    useEffect(() => {
        if (loginError instanceof AuthorizationError) {
            dispatch(promiseError(t('login.invalidCredentials')))
        } else if (loginError) {
            dispatch(promiseError(t('login.error', { error: loginError })))
        }
    }, [dispatch, t, loginError])

    useEffect(() => {
        if (loginResult) {
            if (loginResult.token) {
                setToken(loginResult.token)
                dispatch(login())
                if (returnUrl) {
                    history.push(returnUrl)
                }
            } else {
                setShowResentButton(true)
                dispatch(promiseError(t('login.forgotPasswordNotConfirmedAccount')))
            }
        }
    }, [loginResult, dispatch, t, username, returnUrl, history])

    /*useEffect(() => {
        if (googleApiResponse) {
            googleStart(googleApiResponse)
        }
    }, [googleApiResponse, googleStart])*/

    /*useEffect(() => {
        if (googleError) {
            dispatch(promiseError(t('login.error', { error: googleError })))
        }
    }, [dispatch, t, googleError])*/

    /*useEffect(() => {
        if (googleResult) {
            setToken(googleResult.token)
            dispatch(login())
            if (returnUrl) {
                history.push(returnUrl)
            }
        }
    }, [googleResult, dispatch, googleApiResponse, returnUrl, history])*/

    const handleKeypress = (event: any) => {
        //it triggers by pressing the enter key
        if (event.charCode === 13) {
            handleSubmit(event)
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        if (username && password) {
            loginStart(username, password)
        } else {
            setInputErrorState(true)
            setErrorMessage(t('login.missingCredentials'))
        }
    }

    /*const onGoogleLoginFailure = (res: any) => {
        console.log('[Login failed res:', res)
    }*/
    //href="https://accounts.google.com/signin/oauth?scope=openid%20email&nonce=0394852-3190485-2490558&response_type=token%20id_token&client_id=190908805637-p6a3nbtav17v71ug9jlmok2pj3evknkn.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcharge.sk"
    //href="https://accounts.google.com/signin/oauth?scope=openid%20email&nonce=0394852-3190485-2490558&response_type=token%20id_token&client_id=882750811343-sr0fd57g5dokuqq7am13qmiu3f3kgq2l.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000"
    return (
        <div className={classes.wrapper}>
            <div>
                <form
                    onSubmit={(event: any) => {
                        handleSubmit(event)
                    }}
                >
                    <Grid container alignItems="flex-start" className={classes.wrapperInnerTop} spacing={0}>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            style={{ marginBottom: '37px' }}
                        >
                            <Grid item style={{ justifySelf: 'start' }}>
                                <BackButton
                                    buttonProps={{
                                        onClick: (event: any) => {
                                            history.push('/')
                                        },
                                    }}
                                ></BackButton>
                            </Grid>
                            <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                <PulsingHeader mainWindowScroll={window.pageYOffset} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.title}>
                                {t('login.signIn')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.login}>
                            <CustomTextField
                                errorState={inputErrorState}
                                helperText={!username && inputErrorState ? t('register.required') : undefined}
                                textFieldProps={{
                                    value: username,
                                    onChange: (event: any) => setUsername(event.target.value),
                                    label: t('login.email'),
                                    onKeyPress: handleKeypress,
                                }}
                                password={false}
                                standardChildren={
                                    <img alt="Envelope" src={EnvelopeIcon} className={classes.inputIconLeft} />
                                }
                                errorChildren={
                                    <img alt="EnvelopeError" src={EnvelopeRedIcon} className={classes.inputIconLeft} />
                                }
                            ></CustomTextField>
                        </Grid>
                        <Grid item xs={12} className={classes.password}>
                            <CustomTextField
                                errorState={inputErrorState}
                                helperText={!password && inputErrorState ? t('register.required') : undefined}
                                textFieldProps={{
                                    value: password,
                                    onChange: (event: any) => setPassword(event.target.value),
                                    label: t('login.password'),
                                    onKeyPress: handleKeypress,
                                }}
                                password={true}
                                standardChildren={
                                    <img alt="Lock" src={PasswordIcon} className={classes.inputIconLeft} />
                                }
                                errorChildren={
                                    <img alt="LockError" src={PasswordRedIcon} className={classes.inputIconLeft} />
                                }
                            ></CustomTextField>
                        </Grid>
                        <Grid item xs={12} className={classes.singInButtonContainer}>
                            <AsyncButton
                                buttonProps={{
                                    className: classes.singInButton,
                                    variant: 'contained',
                                    fullWidth: true,

                                    type: 'submit',
                                }}
                                inProgress={loginInProgress}
                            >
                                <p className={classes.singInButtonText}>{t('login.signIn')}</p>
                            </AsyncButton>
                        </Grid>
                        {showResentButton && !loginInProgress && loginResult && !loginResult.token && (
                            <Grid item xs={12} className={classes.resendEmailContainer}>
                                <AsyncButton
                                    buttonProps={{
                                        className: classes.resendEmail,
                                        variant: 'contained',
                                        fullWidth: true,
                                        onClick: (event: any) => {
                                            event.preventDefault()
                                            sendConfirmationEmail(username, setShowResentButton)
                                        },
                                    }}
                                    inProgress={loginInProgress}
                                >
                                    <p className={classes.resendEmailText}>{t('login.resendEmail')}</p>
                                </AsyncButton>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </div>
            <div className={classes.wrapperMiddle}>
                <form noValidate>
                    <Grid container alignItems="flex-start" className={classes.wrapperInnerMiddle} spacing={0}>
                        {tenant && tenant.id === mainTenantId && (
                            <Grid item xs={12} className={classes.register}>
                                {t('login.noaccount')}
                                <RouterLink to="/register"> {t('login.register')}</RouterLink>
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.forgetPassword}>
                            <RouterLink to="/forgot-password">{t('login.forgotPassword')}</RouterLink>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div className={classes.wrapperBottomBackround}>
                <div className={classes.wrapperBottom}>
                    <form noValidate>
                        <Grid container alignItems="flex-start" className={classes.wrapperInnerBottom} spacing={0}>
                            <Grid item xs={12} className={classes.signInWith}>
                                {t('login.orSignInWith')}
                            </Grid>

                            <Grid item xs={12}>
                                <a
                                    target="_self"
                                    href={`${
                                        `https://accounts.google.com/signin/oauth?scope=openid%20email&nonce=0394852-3190485-2490558&response_type=token%20id_token&client_id=` +
                                        googleSettings.clientId +
                                        '&redirect_uri=' +
                                        googleAuthorizationUrl
                                    }`}
                                >
                                    <Grid
                                        container
                                        alignContent="center"
                                        style={{
                                            background: 'white',
                                            height: '60px',
                                            color: 'black',
                                            borderRadius: '14px',
                                            marginBottom: '17px',
                                            fontFamily: "'Mulish', sans-serif",
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            padding: '6px',
                                        }}
                                    >
                                        <Grid
                                            item
                                            style={{
                                                width: '50px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img alt="Google logo" src={LogoGoogle} style={{ width: '20px' }} />
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                width: 'calc(100% - 100px)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>{t('login.google')}</div>
                                        </Grid>
                                        <Grid item style={{ width: '50px' }}></Grid>
                                    </Grid>
                                </a>
                            </Grid>
                            <Grid item xs={12} className={classes.anonymousLogin}>
                                <AsyncButton
                                    buttonProps={{
                                        variant: 'contained',
                                        fullWidth: true,
                                        type: 'button',
                                        className: classes.anonymousLoginButton,
                                        onClick: (event: any) => {
                                            event.preventDefault()
                                            loginAnonymousStart(appKey)
                                        },
                                    }}
                                    inProgress={loginAnonymousInProgress}
                                >
                                    <Grid container alignContent="center">
                                        <Grid
                                            item
                                            style={{
                                                width: '50px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <PermIdentityIcon style={{ width: '20px' }} />
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                width: 'calc(100% - 100px)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div> {t('login.anonymous')}</div>
                                        </Grid>
                                        <Grid item style={{ width: '50px' }}></Grid>
                                    </Grid>
                                </AsyncButton>
                            </Grid>
                            <Grid item xs={12} className={classes.terms}>
                                {t('login.agreeOnSignin')}
                                <a
                                    href={`${config.proxy ?? ''}${config.api}Settings/TermsAndConditions`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('common.terms')}
                                </a>
                                {t('common.and')}
                                <a
                                    href={`${config.proxy ?? ''}${config.api}Settings/Gdpr`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('common.gdpr')}
                                </a>
                                .
                                {tenant.aboutPage ? (
                                    <p>
                                        <a href={tenant.aboutPage} target="_blank" rel="noopener noreferrer">
                                        {t('common.aboutUs')}
                                        </a>
                                    </p>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.terms}></Grid>
                        </Grid>
                    </form>
                    {successMessage.message && (
                        <Snackbar
                            open={Boolean(successMessage.message)}
                            autoHideDuration={Boolean(successMessage.autohide) ? 5000 : undefined}
                            onClose={() => setSuccessMessage({ message: undefined, autohide: false })}
                        >
                            <Alert
                                onClose={() => setSuccessMessage({ message: undefined, autohide: false })}
                                severity="success"
                            >
                                {successMessage.message}
                            </Alert>
                        </Snackbar>
                    )}

                    {errorMessage && (
                        <Snackbar
                            open={Boolean(errorMessage)}
                            autoHideDuration={5000}
                            onClose={() => setErrorMessage(undefined)}
                        >
                            <Alert onClose={() => setErrorMessage(undefined)} severity="error">
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    )}
                    <AppVersion />
                </div>
            </div>
        </div>
    )
}

export default Login
