
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getDefaultLanguage, setDefaultLanguage } from '../../config';
import languageEN from './locales/en/translation.json';
import languageSK from './locales/sk/translation.json';
import languageMK from './locales/mk/translation.json';

const resources = {
  EN: {
    translation: languageEN
  },
  SK: {
    translation: languageSK
  },
  MK: {
    translation: languageMK
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: getDefaultLanguage() || setDefaultLanguage('EN'),
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default, test
    },
    detection: {
      order: ['navigator'],
    },
  });

export const loadCustomTranslations = (customTexts) => {
  // always replace slovak languages as custom
  if (customTexts) {
    // Get the current language translations
    const currentTranslations = i18n.getResourceBundle('SK', 'translation') || {};

    const parsedData = JSON.parse(customTexts);
    // Merge the custom texts with the existing translations
    const mergedTranslations = {
      ...currentTranslations,
      ...parsedData,
    };

    // Update i18next's resources with the new merged translations
    i18n.addResourceBundle('SK', 'translation', mergedTranslations, true, true);
    i18n.changeLanguage('SK');
  }
};
export default i18n;